import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/amuller/github-tkd/website-carbon/website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import LocalImage from './LocalImage';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <p>{`We work `}<strong parentName="p">{`closely`}</strong>{` with you to identify and prepare `}<em parentName="p">{`good-quality data`}</em>{` that can be used to reach your business goals. `}</p>
    </PageDescription>
    <p>{`Data labeling is the process of tagging or categorizing data to make it understandable and useful for machines. In the context of a website, data labeling can help improve search functionality, customer experience, and overall website performance.`}</p>
    <p>{`There are several approaches to data labeling, including manual labeling by humans, semi-automated labeling using machine learning algorithms, and fully automated labeling using artificial intelligence.`}</p>
    <p>{`Manual labeling is labor intensive.  This approach is useful for complex or subjective data that requires human judgment. However, it can be time-consuming and expensive.`}</p>
    <p>{`Semi-automated labeling involves using machine learning algorithms to assist with data labeling. This approach can help speed up the process and reduce errors, but it still requires human oversight to ensure accuracy.`}</p>
    <p>{`Fully automated labeling uses artificial intelligence to quickly label data. This approach is the most efficient and cost-effective, but it requires a suitable pre-trained model. `}</p>
    <p>{`Regardless of the approach used, data labeling is essential for optimizing website performance and enhancing the user experience. By ensuring that data is properly tagged and categorized, websites can improve search accuracy, provide more relevant recommendations, and deliver a better overall experience to their users.`}</p>
    <h2>{`Services`}</h2>
    <ul>
      <li parentName="ul">{`Weak Supervision`}</li>
      <li parentName="ul">{`Data labeling `}</li>
      <li parentName="ul">{`Semi-supervised labeling`}</li>
    </ul>
    <h2>{`Technologies`}</h2>
    <Row className="technology-list" mdxType="Row">
      <Column colLg={3} colMd={3} colSm={2} className="tech-column" mdxType="Column">
        <LocalImage url={"https://www.python.org/"} imageSRC={'/images/tools/python_56.png'} altTxt={"Python"} mdxType="LocalImage" />
        <h3>
  Python
  <span>Our main development language</span>
        </h3>
      </Column>
      <Column colLg={3} colMd={3} colSm={2} className="tech-column" mdxType="Column">
        <LocalImage url={"https://github.com/cleanlab/cleanlab"} imageSRC={'/images/tools/cleanLab_56.png'} altTxt={"Clean Lab"} mdxType="LocalImage" />
        <h3>
  Clean Lab 
  <span>Provide algorithms/interfaces to improve datasets and diagnose/fix issues in them</span>
        </h3>
      </Column>
      <Column colLg={3} colMd={3} colSm={2} className="tech-column" mdxType="Column">
        <LocalImage url={"https://github.com/snorkel-team/snorkel"} imageSRC={'/images/tools/snorkel_56.png'} altTxt={"Snorkel"} mdxType="LocalImage" />
        <h3>
  Snorkel
  <span>Reduces the time, cost, and friction of labeling training data</span>
        </h3>
      </Column>
      <Column colLg={3} colMd={3} colSm={2} className="tech-column" mdxType="Column">
        <LocalImage url={"https://scikit-learn.org/stable/"} imageSRC={'/images/tools/scikit-learn_56.png'} altTxt={"Scikit-learn"} mdxType="LocalImage" />
        <h3>
  Scikit-learn
  <span>Efficiently library for machine learning and statistical modeling. Offering customized tools built on top of sci-kit</span>
        </h3>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      